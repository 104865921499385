import React from "react";
import clsx from "clsx";

import { Carousel } from "@molecules";
import { Container, Image } from "@atoms";

const LogoCarouselAlt = ({ className: _className, logos }) => {
  return (
    <section className={clsx("bg-gray-light py-6", _className)}>
      <Container variant="xl">
        <div className="px-4 md:px-8">
          <Carousel minVisible={3} maxVisible={5} showInactive showArrows>
            {logos?.map(slide => (
              <div className="h-24 px-4 md:px-8" key={slide.url}>
                <div className="relative h-full">
                  <Image
                    image={slide}
                    objectFit="contain"
                    fill
                    ixParams={{ fit: "max" }}
                    transparent
                    draggable={false}
                    imageClassName="mix-blend-multiply"
                  />
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      </Container>
    </section>
  );
};

export default LogoCarouselAlt;
